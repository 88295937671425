/* eslint-disable no-console */

import schemaApi from "@/features/schemas/services/schemaApi";
import axios from "axios";
import { add } from '@/services/apiService';

export default {
    downloadFile,
    deleteFile,
    getSharedFiles,
    getFileById,
    requestFileAccess
};

export async function downloadFile(fileId) {
    let response = await axios
        .get(`/api/Files/Download/${fileId}`, { responseType: 'blob' })

    let blob = new Blob([response.data], { type: response.headers["content-type"] });

    let downloadUrl = window.URL.createObjectURL(blob);
    let link = document.createElement("a");

    if (typeof link.download === "undefined") {
        window.location.href = downloadUrl;
        return;
    }

    link.href = downloadUrl;
    let fileName = getFileName(response.headers['content-disposition']) ?? "file";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
}

export async function uploadFile(fileToUpload) {
    if(!fileToUpload) {
        return;
    }
    let formData = new FormData();
    formData.append("file", fileToUpload);

    let file = await add("files", formData, true)
    return file;
}

function getFileName(disposition) {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;

    if (utf8FilenameRegex.test(disposition)) {
        return decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    }

    // prevent ReDos attacks by anchoring the ascii regex to string start and
    // slicing off everything before 'filename='
    const filenameStart = disposition
        .toLowerCase()
        .indexOf('filename=');

    if (filenameStart < 0) {
        return null;
    }

    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    const partialDisposition = disposition.slice(filenameStart);
    const matches = asciiFilenameRegex.exec(partialDisposition);

    return matches && matches[2] ?
        matches[2] :
        null;
}

export async function deleteFile(fileId) {
    await schemaApi.delete("file", fileId);
}

export function toMegabytes(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
}

export function truncateFileName(fileName) {
    const fileExtension = fileName.split('.').pop();
    const fileNameWithoutExtension = fileName
        .substring(0, fileName.length - fileExtension.length - 1);

    if (fileNameWithoutExtension.length <= 20) {
        return fileName;
    }

    const truncatedName = `${fileNameWithoutExtension.substring(0, 17)}...${fileExtension}`;
    return truncatedName;
}

export async function requestFileAccess(request) {
    try {
        // eslint-disable-next-line no-console
        console.log('Request:', request);
        const response = await axios.post('/api/Menu/files/access-request', {
            entityType: request.entityType,
            entityId: request.entityId,
            workerId: request.workerId,
            action: request.action
        });
        return response.data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error requesting file access:', error)
        throw error
    }
}

export async function getSharedFiles(workerId, permittedUserId) {
    try {
        const response = await axios.get(`/api/Menu/SharedFiles/${workerId}/${permittedUserId}`);
        // eslint-disable-next-line no-console
        console.log('API Response:', response); // Debug log
        return response.data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in getSharedFiles:', error);
        throw error;
    }
}

export async function getFileById(fileId) {
    try {
        const response = await axios.get(`/api/Menu/files/${fileId}`, {
            responseType: 'blob',
            headers: {
                Accept: '*/*'
            }
        });

        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
                try {
                    fileName = decodeURIComponent(fileName);
                } catch {
                    // Use original filename if decoding fails
                }
            }
        }

        return {
            blob: response.data,
            fileName,
            mimeType: response.headers['content-type'] || ''
        };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching file:', error);
        throw new Error('Failed to fetch file');
    }
}